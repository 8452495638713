import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Question from '../Question';
import Score from '../Score';
import './QuizPage.css';

const QuizPage = () => {
  const { slug } = useParams();  // Utilisation de quizId au lieu de slug
  const navigate = useNavigate();
  const [quiz, setQuiz] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true; // Flag pour empêcher les appels multiples
  
    console.log("Slug from URL:", slug); // Log pour vérifier le slug récupéré
    if (!slug) {
      console.log("Le slug est undefined, redirection vers la page d'erreur.");
      navigate('/error'); // Redirection vers une page d'erreur
      return;
    }
  
    const fetchQuizDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/quiz/slug/${slug}`);  // Utilisation de l'ID ici
        
        if (typeof response.data === 'object') {
          console.log("API response:", response.data);
          setQuiz(response.data);
          setQuestions(response.data.quizQuestions || []);
        } else {
          setError("Une erreur est survenue : réponse inattendue du serveur.");
        }
        setIsLoading(false);
      } catch (err) {
        console.log("Erreur lors de la récupération du quiz:", err.message);
        setError(err.message);
        setIsLoading(false);
      }
    };
    
    fetchQuizDetails();
  
    return () => {
      isMounted = false; // Empêcher l'appel si le composant est démonté
    };
  }, [slug, navigate]);  // Utilisation de quizId au lieu du slug

  const handleAnswerOptionClick = (isCorrect) => {
    if (isCorrect) {
      setScore(prevScore => prevScore + 1);
    }

    setTimeout(() => {
      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < questions.length) {
        setCurrentQuestion(nextQuestion);
      } else {
        setShowScore(true);
      }
    }, 250);
  };

  const handleCloseScoreModal = () => {
    setShowScore(false);
    setCurrentQuestion(0);
    setScore(0);
    navigate('/');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="quiz-page-container">
      <Helmet>
        <title>{quiz ? `ABC Quiz - ${quiz.title}` : 'Loading...'}</title>
        <meta name="keywords" content={`quiz, ${quiz ? quiz.title : ''}, knowledge, interactive`} />
        <meta property="og:title" content={quiz ? `ABC Quiz - ${quiz.title}` : 'Loading...'} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={quiz ? `${process.env.REACT_APP_API_URL}/uploads/${quiz.image}` : 'default-image-url'} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={quiz ? `ABC Quiz - ${quiz.title}` : 'Loading...'} />
        <meta name="twitter:description" content={quiz ? quiz.description : 'Loading quiz details...'} />
        <meta name="twitter:image" content={quiz ? `${process.env.REACT_APP_API_URL}/uploads/${quiz.image}` : 'default-image-url'} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div className="quiz-content">
        {showScore ? (
          <Score
            score={score}
            total={questions.length}
            isOpen={showScore}
            onClose={handleCloseScoreModal}
          />
        ) : (
          questions.length > 0 && (
            <div>
              {quiz && (
                <div className="quiz-header">
                  <h1>{quiz.title}</h1>
                  <img src={`${process.env.REACT_APP_API_URL}/quiz-backend/uploads/${quiz.image}`} alt={quiz.title} className="quiz-image" />
                </div>
              )}
              {questions[currentQuestion] && (
                <Question
                  question={questions[currentQuestion]}
                  onAnswerClick={handleAnswerOptionClick}
                />
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default QuizPage;
