import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import './QuizPage.css';

const QuizMangaPage = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/quiz`);
        const mangaQuizzes = res.data.filter(quiz => quiz.category === 'manga');
        setQuizzes(mangaQuizzes);
      } catch (error) {
        setError('Error fetching quizzes: ' + error.message);
        console.error('Error fetching quizzes:', error);
      }
    };
    fetchQuizzes();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  const pageTitle = "Quiz Manga - ABC Quiz";
  const pageDescription = "Découvrez nos quiz sur les mangas. Testez vos connaissances et amusez-vous avec nos quiz interactifs.";
  const pageUrl = window.location.href;
  const imageUrl = "https://abcquiz.fr/static/media/logo.f19e6135ea883b1bf815.png"; // Remplace par l'URL de l'image de ton choix
  
  return (
    <div className="quiz-page">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content="quiz manga, quiz anime, connaissances, interactif" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:image" content={imageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={imageUrl} />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <h1>Quiz Manga</h1>
      <div className="quiz-grid">
        {quizzes.map((quiz) => (
          <div key={quiz.id} className="quiz-card">
            <img src={`${process.env.REACT_APP_API_URL}/quiz-backend/uploads/${quiz.image}`} alt={quiz.title} className="quiz-image" />
            <h2>{quiz.title}</h2>
            <p>Nombre de questions: {quiz.numQuestions}</p>
            <Link to={`/quiz/${quiz.id}`} className="quiz-button">Commencer le Quiz</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuizMangaPage;
