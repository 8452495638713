import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import './QuizPage.css';

const QuizSeriesPage = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/quiz`);
        const seriesQuizzes = res.data.filter(quiz => quiz.category === 'series');
        setQuizzes(seriesQuizzes);
      } catch (error) {
        setError('Error fetching quizzes: ' + error.message);
        console.error('Error fetching quizzes:', error);
      }
    };
    fetchQuizzes();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  const pageTitle = "Quiz Série - ABC Quiz";
  const pageDescription = "Découvrez nos quiz sur les séries TV. Testez vos connaissances et amusez-vous avec nos quiz interactifs.";
  const pageUrl = window.location.href;

  return (
    <div className="quiz-page">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content="quiz séries, quiz TV, connaissances, interactif" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:image" content="URL_DE_TA_IMAGE" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content="URL_DE_TA_IMAGE" />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <h1>Quiz Série</h1>
      <div className="quiz-grid">
        {quizzes.map((quiz) => (
          <div key={quiz.id} className="quiz-card">
            <img src={`${process.env.REACT_APP_API_URL}/quiz-backend/uploads/${quiz.image}`} alt={quiz.title} className="quiz-image" />
            <h2>{quiz.title}</h2>
            <p>Nombre de questions: {quiz.numQuestions}</p>
            {quiz.slug ? (
              <Link to={`/quiz/${quiz.slug}`} className="quiz-button">Commencer le Quiz</Link>
            ) : (
              <span>Slug not available</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuizSeriesPage;
